"use strict";

angular.module("informaApp")
    .service("DrugClassesService", ['BaseApiService', "ConstantsSvc", function (BaseApiService, ConstantsSvc) {
        return {
            getDrugClasses: function(filter){
                
                return BaseApiService.post('drugclasses', filter)
                .then(function (res) {
                    return res.data.data;
                });
                /*
                 return $http.post(ConstantsSvc.API.URL + "drugclasses", filter).then(function (res) {
                    return res.data.data;
                });
                */
            }
        };
    }]);